import React from 'react';
import cx from 'classnames';
import LocalizedMessage from 'components/LocalizedMessage';
import LangSwitcher from 'components/LangSwitcher';
import { AgenciesDropdown } from 'components/AgenciesDropdown';
import { IProfile } from 'types';
import classes from './Header.module.scss';

interface IProps {
  isAuthorized: boolean;
  profile: IProfile;
  switchMenu: () => void;
  logOut: () => void;
  setCurrentAgency: (agencyId: number) => void
}

const Header = (props: IProps) => {
  const { isAuthorized, profile, switchMenu, setCurrentAgency, logOut } = props;

  if (!isAuthorized) {
    return null;
  }

  return (
    <div className={classes.Container}>
      <div className={classes.leftPart}>
        {(profile.role.name === 'SUPER_ADMIN' || profile.role.name === 'ADMIN') && (
        <span
          className={cx('btn', 'btn-primary', classes.switchButton)}
          onClick={switchMenu}
          data-test='sidebar-minimalize'
        >
          <i className='fa fa-bars' />
        </span>
        )}
        <AgenciesDropdown
          items={profile.agencies}
          onSelect={(agencyId) => { setCurrentAgency(agencyId); }}
          currentAgencyId={profile.extend.agency.id}
        />
      </div>
      <ul className='nav navbar-top-links navbar-right'>
        <li>
          <div className={classes.Profile}>
            {profile.firstName}
            {' '}
            {profile.lastName}
            {' '}
            <em>
              {'('}
              <LocalizedMessage id={`role.${profile.role.name.replace('_', '').toLocaleLowerCase()}`} />
              {')'}
            </em>
          </div>
        </li>
        {' '}
        <li>
          <LangSwitcher />
        </li>
        {' '}
        <li>
          <button
            className='pseudo-link'
            onClick={logOut}
            data-test='logout'
          >
            <i className='fa fa-sign-out' />
            <LocalizedMessage
              id='logout'
            />
          </button>
        </li>
        {' '}
        <li>
          <a href='mailto:support@aizek.io?subject=Appeal%20in%20support'>
            <i className='fa fa-question' />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Header;
